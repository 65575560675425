<template>
    <div class="loginPage">
        <LoginDialog :isRouter="true"/>
    </div>
</template>
<script>
import LoginDialog from '@/components/header/loginDialog'
export default {
    components: {
        LoginDialog
    },
}
</script>